/* Standard Light Mode Farben */
:root {
    --background-color: #ffffff;
    --card-background-color: #ffffff;
    --text-color: #000000;
    --link-color: black;
    --hover-color: black;
    --button-color: #6c6c6c;
    --selected-color: black;
    --green-color: green;
    --gray-color: darkslategray;
    --red-color: red;
    --header-bg-color: #f8f9fa;
    --footer-bg-color: #e9ecef;
    --input-bg-color: #ffffff;
    --input-border-color: #ced4da;
    --input-text-color: #495057;
    --card-bg-color: #ffffff;
    --scroll: #bfc6c7;
}

/* Dark Mode Farben */
.dark-mode {
    --card-background-color: #333334;
    --background-color: #121212;
    --text-color: #e0e0e0;
    --link-color: #e0e0e0;
    --hover-color: #ffffff;
    --button-color: #cccccc;
    --selected-color: #ffffff;
    --green-color: #00ff00;
    --gray-color: #ffffff;
    --red-color: #ff6347;
    --header-bg-color: #1f1f1f;
    --footer-bg-color: #1b1b1b;
    --input-bg-color: #1e1e1e;
    --input-border-color: #444444;
    --input-text-color: #e0e0e0;
    --card-bg-color: #1e1e1e;
    --scroll: #646064;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
    background-color: transparent !important; /* Hintergrundfarbe beim Autofill */
    transition: background-color 5000s ease-in-out 0s; /* Verhindert, dass Chrome das Styling ändert */
    -webkit-text-fill-color: var(--text-color) !important; /* Sicherstellen, dass die Textfarbe korrekt dargestellt wird */
}

/* Scrollbar-Styling für Chrome (Webkit-basierte Browser) */
::-webkit-scrollbar {
    width: 12px; /* Breite des Scrollbalkens */
}

::-webkit-scrollbar-track {
    background: transparent; /* Transparenter Hintergrund für die Scroll-Schiene */
}

::-webkit-scrollbar-thumb {
    background-color: var(--scroll); /* Farbe des Scrollbalkens (Daumen) */
    border-radius: 10px; /* Abrundung des Scrollbalkens */
    border: 2px solid rgba(0, 0, 0, 0); /* Optional: Abstand durch transparenten Rand */
}

.MuiPaper-root {
    background-color: var(--card-background-color) !important;
    color: var(--text-color) !important;
}

 .MuiButtonBase-root{
     color: var(--text-color) !important;
 }

.custom-divider {
    border: 1px solid var(--input-border-color) !important;
    border: none;
}

.MuiFormLabel-root{
    color: var(--text-color) !important;
}

.MuiSelect-select{
    border: 1px solid var(--input-border-color) !important;
}

.MuiTab-root{
}

.MuiInputBase-input{
    border: 1px solid var(--input-border-color) !important;
    border-radius: 10px !important; /* Runde Ecken hinzufügen */
}

.MuiInputBase-root{
    color: var(--text-color) !important;
}

.MuiOutlinedInput-root{
    border-color: white !important;
}

.MuiPaginationItem-root{
    color: var(--text-color) !important;

}

.MuiTypography-root{
    color: var(--text-color) !important;
}

/* Allgemeine Stile */
body {
    background-color: var(--background-color) !important;
    color: var(--text-color);
}

a {
    color: var(--link-color) !important;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: var(--hover-color) !important;
}

button {
    color: var(--button-color) !important;
    background: transparent !important;
    border: 1px solid var(--button-color);
}

button:hover {
    color: var(--hover-color) !important;
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: underline !important;
}

button:active,
button:focus,
button:checked {
    color: var(--hover-color) !important;
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: underline !important;
}

.iconButton {
    color: var(--button-color) !important;
    background: transparent !important;
    border: 1px solid var(--button-color);
}

.iconButton:hover {
    color: var(--hover-color) !important;
    background-color: rgba(255, 255, 255, 0.1);
    text-decoration: none !important;
}

.iconButton:active,
.iconButton:focus,
.iconButton:checked {
    color: var(--hover-color) !important;
    background-color: rgba(255, 255, 255, 0.2);
    text-decoration: none !important;
}

.Mui-selected {
    color: var(--selected-color) !important;
    background: transparent;
    text-decoration: underline !important;
}

/* Header */
header {
    background-color: var(--header-bg-color);
}

header h1 {
    color: var(--text-color);
}

/* Footer */
footer {
    background-color: var(--footer-bg-color);
}

footer p {
    color: var(--text-color);
}

/* Formularelemente */
input, select, textarea {
    background-color: var(--input-bg-color);
    color: var(--input-text-color);
}

input:focus, select:focus, textarea:focus {
    border-color: var(--hover-color);
}

input::placeholder, textarea::placeholder {
    color: var(--gray-color);
}

/* Karten-Design (z.B. für Boxen oder Widgets) */
.card {
    background-color: var(--card-bg-color);
}

.card h2 {
    color: var(--text-color);
}

/* Farben für bestimmte Klassen */
.green {
    color: var(--green-color) !important;
}

.gray {
    color: var(--gray-color) !important;
}

.red {
    color: var(--red-color) !important;
}

.highlight {
    font-weight: bold;
}


table th, table td {
    color: var(--text-color);
}

table th {
    background-color: var(--card-background-color);
}

.custom-scrollbar {
    overflow-y: auto; /* Erzwingt eine vertikale Scrolleiste, wenn nötig */
    scrollbar-width: thin; /* Für Firefox */
    scrollbar-color: #888 #e0e0e0; /* Für Firefox */
}

.custom-scrollbar::-webkit-scrollbar {
    width: 12px; /* Breite der Scrolleiste */
}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #e0e0e0; /* Farbe der Scrolleiste auf der Spur */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #888; /* Farbe der Scrolleiste */
    border-radius: 10px; /* Rundung der Scrolleiste */
    border: 2px solid #e0e0e0; /* Abstand zwischen Scrolleiste und Spur */
}



